import { createStore } from 'vuex'

export default createStore({
  state: {
    userData: {  
      job: '开发',  
      username: 'newbeeW',  
      webstatus: 'VIP'  
    } 
  },
  getters: {
    userData: state => state.userData
  },
  mutations: {
    setUserData(state, payload) {  
      state.userData = payload
    }
  },
  actions: {
    updateUserData({ commit }, payload) {  
      // 在这里你可以进行异步操作，比如从API获取数据  
      commit('setUserData', payload);  
    }  
  },
  modules: {
  }
})
