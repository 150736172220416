import axiosInstance from '../utils/http';
import store from '../store';


// 设置基础URL（根据你的后端服务器地址修改）  
const baseURL1 = 'http://diagapi.wwftool.top/api';
const baseURL2 = 'http://diagapi.us.wwftool.top/api';
const baseURL3 = 'http://diagapi.cn.wwftool.top/api';

// 选择 baseURL 的辅助函数  
const chooseBaseURL = (useBaseURL) => {
    return useBaseURL ? baseURL3 : baseURL2;
};

//根据本地保存的token验证是否在有效期内
export const getUserInfo = () => {
    return axiosInstance.get(`${baseURL1}/v1/user/userinfo`);
};

//查询放到outcode
export const getOutCodebySn = (limit, sn) => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getOutCodeInfo?limit=${limit}&sn=${sn}`);
};
//下载外码文件
export const downloadOutCodebyId = async (id) => {
    try {
        const response = await axiosInstance.get(`${baseURL1}/v1/diag/downloadOutCodeInfo?id=${id}`);


        // 创建一个 URL 并触发下载  
        console.log(response.headers);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;


        // 从响应头中提取文件名  
        console.log(response.headers); 
        const contentDisposition = response.headers['content-disposition'];
        const filename = contentDisposition.split('; ')[1].split('=')[1]; // 假设文件名总是第二个部分，并且等号后面紧跟着文件名  

        link.setAttribute('download', filename);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url); // 释放 URL 对象  
    } catch (error) {
        console.error('Error downloading file:', error);
        // 这里可以添加错误处理逻辑，比如显示一个错误消息  
    }
};

// 查询测试接头
export const getBlackSn = (limit, sn) => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getBlackSn?limit=${limit}&sn=${sn}`);
};
// 添加测试接头
export const addBlackSn = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/diag/setBlackSn`, data);
};
// 删除测试接头
export const deleteBlackSn = (sn) => {
    return axiosInstance.delete(`${baseURL1}/v1/diag/deleteBlackSn/${sn}`);
};

// 查询测试接头
export const getTestSn = () => {
    return axiosInstance.get(`${baseURL1}/v1/diag/getTestSn`);
};
// 添加测试接头
export const addTestSn = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/diag/setTestSn`, data);
};
// 删除测试接头
export const deleteTestSn = (sn) => {
    return axiosInstance.delete(`${baseURL1}/v1/diag/deleteTestSn/${sn}`);
};

// 用户登录请求  
export const userLogin = (data) => {
    return axiosInstance.post(`${baseURL1}/v1/user/token`, data);
};

//查询服务器后台日志数据
export const getPageInfo = (limit, sn, useBaseURL = true) => {
    const baseURL = chooseBaseURL(useBaseURL);
    return axiosInstance.get(`${baseURL}/v1/setlog/getpagelog?limit=${limit}&sn=${sn}`);
};

export async function isAuthenticated() {
    const token = localStorage.getItem('authToken');

    if (!token) {
        return false; // 如果没有 token，直接返回 false  
    }

    try {
        const response = await getUserInfo(); // 等待 getUserInfo() 的结果  
        store.commit('setUserData', response.data.userInfo); // 更新 Vuex store  
        return true; // 验证成功，返回 true  
    } catch (error) {
        console.error('Error validating token:', error); // 记录错误  
        return false; // 验证失败，返回 false  
    }
}